import React from "react";
import MamehaVideo from './../videos/WebSite_3DShowcaseMameha_VideoQHD.mp4'
import MPuzhenVideo from './../videos/WebSite_3DShowcaseMPuzhen_VideoQHD.mp4'
import Mu3Video from './../videos/WebSite_3DShowcaseMu3_VideoQHD.mp4'
import CookplayVideo from './../videos/WebSite_3DShowcaseCookplay_VideoQHD.mp4'
import YapeVideo from './../videos/WebSite_3DShowcaseYape_VideoQHD.mp4'
import GhostPrintVideo from './../videos/WebSite_3DShowcaseGhostprint_VideoQHD.mp4'

export const VideoMameha = () => {
    return (
        <video src={MamehaVideo} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}

export const VideoMPuzhen = () => {
    return (
        <video src={MPuzhenVideo} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}

export const VideoMu3 = () => {
    return (
        <video src={Mu3Video} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}

export const VideoCookplay = () => {
    return (
        <video src={CookplayVideo} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}

export const VideoYape = () => {
    return (
        <video src={YapeVideo} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}

export const VideoGhostPrint = () => {
    return (
        <video src={GhostPrintVideo} type="video/mp4" autoPlay={true} muted={true} loop={true} playsInline={true}></video>
    )
}