import classNames from "classnames";
import { Link } from "gatsby";
import React, { useState, useRef } from "react";
import Slider from "react-slick";
import FloatingMagicNavigator from "../components/FloatingMagicNavigator";
import PageLayout from "../components/PageLayout";
import { videos } from "../context/consts";
import { splitAndSpan } from "../utils/parsers";
import * as styles from './industrial-design.module.css'
import Seo from "../components/Seo";
import { BrowserView } from "react-device-detect";
import { VideoMameha, VideoMPuzhen, VideoMu3, VideoYape, VideoGhostPrint, VideoCookplay } from "../components/Videos";


const IndustrialDesignPage = () => {

    const [video, setVideo] = useState(videos[0])
    const [hide, setHide] = useState(false);
    const slider = useRef();

    const beforeChange = (current, next) => {
        setHide(true)
        setTimeout(() => {
            setHide(false)
        }, 2000)
    }

    const afterChange = current => {
        setVideo(videos[current])
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
        swipeToSlide: true,
        pauseOnHover: false,
        cssEase: 'linear',
        lazyLoad: false,
        useTransform: false,
        beforeChange: (current, next) => beforeChange(current, next),
        afterChange: current => afterChange(current)
    };

    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };

    return (
        <React.Fragment>
            <BrowserView>
                <div className={classNames(styles.absoluteClickableContainer, styles.leftClickableContainer)} onClick={previous}></div>
                <div className={classNames(styles.absoluteClickableContainer, styles.rightClickableContainer)} onClick={next}></div>
            </BrowserView>

            <div className="slider-container">
                <Slider ref={(c) => (slider.current = c)} {...settings}>
                    <div><VideoMu3 /></div>
                    <div><VideoCookplay /></div>
                    <div><VideoGhostPrint /></div>
                    <div><VideoYape /></div>
                    <div><VideoMPuzhen /></div>
                    <div><VideoMameha /></div>
                </Slider>
                <Link className={classNames(styles.label, hide ? styles.hide : '')} to={video.pageUri}>
                    {splitAndSpan(video.title || '')}
                </Link>
            </div>

            <PageLayout withFooter={false}>
                <FloatingMagicNavigator />
                <Seo title="Industrial Design" />
            </PageLayout>
        </React.Fragment>


    )
}

export default IndustrialDesignPage